// Basic Colors
$basic-dark: #202c3e; // Primary color
$basic-white: white; // Secondary color
$basic-light-blue: #f0f3f8; // Background color
$basic-dark-blue: #35445a; // Buttons color
$basic-light-grey: #eee;
$basic-grey: #e5e5e5;

$blue: #1c84c6; // Success color
$lazur: #23c6c8; // Info color
$yellow: #f8ac59; // Warning color
$red: #ed5565; // Danger color
$green: #31b255;
$purple: #d95d5b; // Error color

// Statuses
$baseStatus: $basic-light-grey;
$unconfirmed: #eeefe9;
$confirmed: #1681c6;
$active: #33b555;
$paused: #cea048;
$completed: #37435b;
$archived: transparent;

// Various colors
$text-black: black; // primary text
$text-dark-grey: #1c1c1c; // cards text
$text-middle-grey: #333333; // pie chart text
$text-grey: #8d8d8d; // label
$text-white: white;
$text-active-link: #39b7ba;
$text-dark-blue: #35445a;

// Media card colors
$media-type-tv: #477fd3;
$media-type-web: #d26948;
$media-type-outdoor: #d3a03b;
$media-type-press: #4352d9;
$media-type-radio: #9f57fa;
$media-type-mix: #6daa63;

// Main page colors
$main-success: #35a8ab;
$main-light-grey: #ededed;
$main-dark-grey: #8a8d8c;
$main-grey: #dbdbdb;
$main-lazur: #39af9e;

:root {
  --color-basic-white: #fff;
  --color-basic-dark: #202c3e;
  --color-grey: #e5e5e5;
  --color-light-blue: #f0f3f8;
  --color-light-grey: #eee;
  --color-super-light-grey: #f4f4f4;
  --color-green: #31b255;
  --color-green-hover: #2ea850;
  --color-green-active: #2a9c48;
  --color-azure: #35a8ab;
  --color-red: #ed5565;
  --color-red-hover: #db4e5e;
  --color-red-active: #d24b5c;
  --color-blue: #1c84c6;
  --color-white-blue: #c9f0ff;
  --color-yellow: #f8ac59;
  --color-yellow-translucent: rgba(248, 172, 89, 0.5);
  --color-beige: rgba(255, 243, 205, 0.3);
  --color-primary: #35445a;
  --color-primary-hover: #2e3a4e;
  --color-primary-active: #2c364a;
  --color-secondary: #8a9eb1;
  --color-secondary-hover: #8498aa;
  --color-secondary-active: #7d91a2;
  --color-button-text: #fff;

  --color-text-white: white;
  --color-text-black: black;
  --color-text-grey: #8d8d8d;
  --color-text-middle-grey: #333333;
  --color-text-dark-grey: #1c1c1c;
  --color-text-active-link: #39b7ba;
  --color-text-dark-blue: #35445a;

  --color-status-active: #33b555;
  --color-status-archived: transparent;
  --color-status-base: #eee;
  --color-status-completed: #37435b;
  --color-status-confirmed: #1681c6;
  --color-status-paused: #cea048;
  --color-status-unconfirmed: #eeefe9;

  --color-media-type-tv: #477fd3;
  --color-media-type-web: #d26948;
  --color-media-type-outdoor: #d3a03b;
  --color-media-type-press: #4352d9;
  --color-media-type-radio: #9f57fa;
  --color-media-type-mix: #6daa63;
  
  --color-file-status-current: var(--color-green);
  --color-file-status-draft: var(--color-media-type-outdoor);
  --color-file-status-archive: LightGray;

  --checkbox-border-color: #878787;
  --row-selected-background-color: #dbecfa;
  --row-selected-translucent-background-color: rgba(219, 236, 250, 0.5);
  --row-selected-hover-background-color: #c9e3f8;
  --border-color: #ced4da;
  --border: 1px solid var(--border-color);

  --focus-ring: 0 0 0 0.2rem rgba(96, 125, 139, 0.07) !important;
  --focus-ring-dark: 0 0 0 0.2rem rgba(96, 125, 139, 0.1);
  --focus-ring-blue: 0 0 0 0.2rem #a6d5fa;
  --focus-checkbox-shadow: 0 0 3px 1px #1c84c6;

  --rdg-background-color: transparent;
  --rdg-header-background-color: #35445a;
  --data-grid-title-row-color: var(--color-secondary);
}
