$header-height: 65px;
$footer-height: 50px;
$administration-sidebar-width: 250px;
$flowchart-row-height: 48px;

:root {
  --header-height: 65px;
  --footer-height: 50px;
  --administration-sidebar-width: 65px;
  --flowchart-row-height: 65px;
}
